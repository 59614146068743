//@flow
export const CustomOpengraphRenderer = ({
  object
}: {
  object?: ?{
    +title?: ?string,
    +description?: ?string,
    +sharingImage?: ?string
  }
}) => {
  return (
    null
  );
};

type Props = {
  metadata: metadata_metadata
};
const OpengraphFragmentRenderer = ({
  metadata: { title, description, sharingImage } = {}
}: Props) => (
  null
);

export default OpengraphFragmentRenderer;
