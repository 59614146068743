//@flow
import * as React from 'react';
import Layout from '../Layout';
import Flex, {FlexItem} from 'styled-flex-component';
import styled from 'styled-components';
import program from '../../static/program.png'
import forside from '../../static/forside.png'
import { Image } from '../Styled';


const Program = styled(Image)`
  width: 800px;
  max-width: 100%;
  padding-bottom: 14px;
`;

const Index = ({ variables, query, environment, queryProps, error }) => {

  const props = {
    programPage: true,
    events: [
      {
        id: 0,
        date: '2019-03-13',
        timeStart: '17:00',
        title: 'Felles oppmøte',
        description: '',
        location: 'Radisson Blu Royal Garden'
      },
      {
        id: 1,
        date: '2019-03-13',
        timeStart: '17:30',
        title: 'Sosial aktivitet med Deloitte',
        description: '',
        location: ''
      },
      {
        id: 2,
        date: '2019-03-13',
        timeStart: '20:00',
        title: 'Middag',
        description: '',
        location: 'Selma Bar'
      },
      {
        id: 3,
        date: '2019-03-14',
        timeStart: '07:00',
        timeEnd: '08:45',
        title: 'Frokost',
        description: '',
        location: 'Radisson Blu Royal Garden'
      },
      {
        id: 4,
        date: '2019-03-14',
        timeStart: '09:00',
        timeEnd: '09:20',
        title: 'Åpning av konferansen',
        description: '',
        location: 'Frimurerlogen'
      },
      {
        id: 5,
        date: '2019-03-14',
        timeStart: '09:20',
        timeEnd: '10:00',
        title: 'Presentasjon av studentkonsulentselskapene',
        description: '',
        location: ''
      },
      {
        id: 6,
        date: '2019-03-14',
        timeStart: '10:15',
        timeEnd: '11:45',
        title: 'WS: World Café Bar ',
        description: 'Erfaringsutveksling',
        location: ''
      },
      {
        id: 7,
        date: '2019-03-14',
        timeStart: '12:00',
        timeEnd: '13:00',
        title: 'Lunch',
        description: '',
        location: ''
      },
      {
        id: 8,
        date: '2019-03-14',
        timeStart: '13:15',
        timeEnd: '14:45',
        title: 'Sofaprat og techtalk',
        description: 'Med Deloitte',
        location: ''
      },
      {
        id: 9,
        date: '2019-03-14',
        timeStart: '14:45',
        timeEnd: '15:00',
        title: 'Pause',
        description: '',
        location: ''
      },
      {
        id: 10,
        date: '2019-03-14',
        timeStart: '15:00',
        timeEnd: '15:50',
        title: 'Foredrag: Hvordan bygge opp et konsulentselskap',
        description: 'Med Netlight',
        location: ''
      },
      {
        id: 11,
        date: '2019-03-14',
        timeStart: '16:00',
        timeEnd: '16:35',
        title: 'Foredrag: Nettverksbasert salg',
        description: '',
        location: ''
      },
      {
        id: 12,
        date: '2019-03-14',
        timeStart: '16:50',
        timeEnd: '17:45',
        title: 'Foredrag: Agile Project Management',
        description: 'Med Capgemini Invent',
        location: ''
      },
      {
        id: 13,
        date: '2019-03-14',
        timeStart: '19:30',
        timeEnd: '22:00',
        title: 'Bankett',
        description: '',
        location: ''
      },
      {
        id: 14,
        date: '2019-03-14',
        timeStart: '22:00',
        title: 'Fest',
        description: '',
        location: ''
      },
      {
        id: 15,
        date: '2019-03-15',
        timeStart: '07:30',
        timeEnd: '10:00',
        title: 'Frokost',
        description: 'Takk for besøket, og vel hjem!',
        location: ''
      }
    ]
  };

  return (
    <Layout
      responsive
      {...{ error, props }}
      metadata={props && props.programPage}
      contentRenderer={({ props, error }) => {
        return (
          <>
            <Flex wrap={'true'} justifyCenter>
            <FlexItem>
            <Program src={program} alt="program" />

              </FlexItem>
                <Program src={forside} alt="forside" />
            </Flex>
          </>
        );
      }}
    />
  );
};

export default Index;
