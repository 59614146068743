import * as React from 'react';

import styled from 'styled-components';
import Flex, { FlexItem } from 'styled-flex-component';
import { ResponsiveContent, NoBulletUl } from '../Styled';
import jrc_logo from '../../static/jrc_logo.png';

const Container = styled('div')`
  padding: 2.5em 0em;
  border-top: 1px solid #e2e9f1;
  background-color: #f7f9fb;
`;

const RightBorderFlex = styled(FlexItem)`
  border-right: 1px solid #e2e9f1;
  margin-right: 20px;

  @media only screen and (max-width: 800px) {
    border-right: 0;
    margin-right: 10px;
  }
`;

const Footer = () => (
  <Container>
    <ResponsiveContent>
      <Flex wrap>
        <RightBorderFlex grow noShrink basis="300px">
          <NoBulletUl>
            <li>
              <a href="http://www.jrc.no">
                {' '}
                <img style={{ width: 150 }} src={jrc_logo} alt="jrc logo" />
              </a>
            </li>
            <li>Bergsligata 9 </li>
            <li>7017 Trondheim</li>
            <li>Orgnr. 998354730 </li>
          </NoBulletUl>
        </RightBorderFlex>
        <RightBorderFlex grow noShrink basis="300px">
          <NoBulletUl>
            <li>
              <strong>Kontakt</strong>
            </li>
            <li>
              <a href="mailto:kontakt@juniorconsulting.no">
                kontakt[at]juniorconsulting.no{' '}
              </a>
              <a href="http://www.jrc.no">www.jrc.no</a>
            </li>
          </NoBulletUl>
        </RightBorderFlex>
        <FlexItem grow={1} basis="350px">
          <NoBulletUl>
            <li>
              <strong>Om</strong>
            </li>
            <li>
              SKK 2019 er en Studentkonsulentkonferanse arrangert av Junior
              Consulting AS i Trondheim.
            </li>
          </NoBulletUl>
        </FlexItem>
      </Flex>
    </ResponsiveContent>
  </Container>
);

export default styled(Footer)`
  padding: 5em 0em;
  border-top: 1px solid #e2e9f1;
  background-color: #f7f9fb;
`;
