//@flow
import React from 'react';

import { Section } from './components/Styled';
import { Image, CenterIt } from './components/Styled';
// import { QueryRenderer, graphql } from 'react-relay';

import Collaborators from './components/Collaborators/Collaborators';
import Flex, { FlexItem } from 'styled-flex-component';
import styled from 'styled-components';
import HSP from './components/Frontpage/HSP';
import WelcomeScreen from './components/Frontpage/WelcomeScreen';
import Layout from './components/Layout';
import jrc_logo from './static/jrc_logo.png';
import netlight from './static/netlight.png';
import capgemini from './static/capgemini.jpg';
import sparebank from './static/sparebank.jpg'

const AboutSection = props => {
  return (
    <>
      <Flex justifyAround wrapReverse>
        <FlexItem grow={1} basis="500px">
          <CenterIt>
            <h1>Studentkonsulentkonferansen 2019</h1>

            <p>
              <i>
                Junior Consulting (JrC) ønsker å samle Skandinavias dyktigste
                studentkonsulenter for å skape en spennende arena for læring,
                erfaringsutveksling og nettverksbygging. Studentkonsulenthusene
                varierer i størrelse og aktivitet, men en fellesnevner er at vi
                alle brenner for det vi gjør og ønsker å få en smakebit av livet
                som konsulent, mens vi fortsatt er studenter. En slik konferanse
                gir studentkonsulenthusene en unik mulighet til å lære av
                samarbeidspartnerne og hverandre, som gir rom for videre vekst
                med nye innfallsvinkler i etterkant av konferansen. Tidligere
                har deltakere på SKK kommet fra studentkonsulenthus i hele
                Skandinavia, blant annet NTNU, NHH, BI Oslo, Chalmers tekniska
                högskola, Lunds universitet og Aarhus universitet. Denne våren
                ønsker vi å ha ca. 80 deltakende studenter på konferansen i
                Trondheim. Studentene ankommer onsdag ettermiddag for
                sosialisering. Torsdag legges det opp til et heldagsarrangement
                fylt med workshops og foredrag, etterfulgt av bankett på
                kvelden. Konferansen avsluttes fredag med felles frokost.
              </i>
            </p>
          </CenterIt>
        </FlexItem>
        <FlexItem>
          <CenterIt>
            <Image
              style={{ width: 350, maxWidth: '100%', marginLeft: '2em' }}
              src={jrc_logo}
              alt="jrc logo"
            />
          </CenterIt>
        </FlexItem>
      </Flex>
    </>
  );
};

const Centered = styled('div')`
  text-align: center;
`;

const EventsSection = ({ query }: { query: pages_index_QueryResponse }) => (
  <>
    {/* This should be a generic graphql query */}
    <Flex wrap={'true'}>
      {query.pages &&
        query.pages.filter(Boolean).map(element => (
          <FlexItem key={element.slug} basis={'400px'} grow={1}>
            <h2> {element.title} </h2>
            <p>{element.ingress}</p>
            <Centered />
          </FlexItem>
        ))}
    </Flex>
  </>
);

const Index = ({ variables, environment, queryProps, error }) => {
  const props = {
    currentMetaData: {
      year: 2019,
      startDate: '2019-03-13',
      mainCollaborator: 'Deloitte',
      collaborators: [
        {
          id: 0,
          name: 'Netlight',
          url: 'http://www.netlight.no',
          logo: netlight,
          description: 'Netlight er et uavhengig, verdidrevet konsulentfirma innen IT. Virksomheten foregår i Europa med kontorer i Oslo, Stockholm, München, Hamburg, Berlin, Zürich, Helsinki og København. Netlight har vært en stabil leverandør av profesjonelle konsulenttjenester siden starten i 1999 og sysselsetter i dag over 1000 konsulenter der 120 av disse jobber i Oslo. Netlight rendyrker begrepet IT-konsulent til å omhandle talenter som sammen skaper verdifulle resultater for våre kunder og løser problemer få har løst før oss.'
        },
        {
          id: 1,
          name: 'Capgemini Invent',
          url: 'http://www.capgemini.no',
          logo: capgemini,
          description: 'Capgemini Invent is an integral part of Capgemini, a global leader in consulting, technology services and digital transformation. As the digital innovation, consulting and transformation brand of the Capgemini Group, Capgemini Invent helps CxOs envision and build what’s next for their organizations. Located in more than 30 offices and 10 creative studios around the world, its 6,000+ strong team combines strategy, technology, data science and creative design with deep industry expertise and insights, to develop new digital solutions and business models of the future'
        },
        {
          id: 2,
          name: 'SpareBank 1 SMN',
          url: 'https://www.sparebank1.no/nb/smn/',
          logo: sparebank,
          description: 'Vi ønsker å takke SpareBank 1 SMN for økonomisk bidrag til Studentkonsulentkonferansen.'
        }
      ]
    },
    programPage: true,
    events: []
  };

  return (
    <Layout
      {...{ error, props }}
      contentRenderer={({ props }) => (
        <>
          <section>
            <WelcomeScreen currentMetaData={props.currentMetaData} />
          </section>
          <Section>
            <AboutSection {...props} />
          </Section>
          {props.currentMetaData.mainCollaborator && (
            <Section>
              <HSP />
            </Section>
          )}
          {props.currentMetaData.collaborators && (
            <Section>
              <Collaborators
                showDescription
                collabarators={props.currentMetaData.collaborators}
              />
            </Section>
          )}
          <Section style={{ borderBottom: 0 }}>
            <EventsSection query={props} />
          </Section>
        </>
      )}
    />
  );
};

export default Index;
