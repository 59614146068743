//@flow
import React from 'react';
import Countdown from '../Countdown';
import Flex, { FlexItem } from 'styled-flex-component';
import styled from 'styled-components';
import { CenterIt } from '../Styled';
import glos from '../../static/glos.jpg'
type Props = {
  currentMetaData: WelcomeScreen_currentMetaData
};

const MainContainer = styled(CenterIt)`
  @media only screen and (min-width: 800px) {
    background: rgba(0, 0, 0, 0.2);
  }
  height: 100%;
  minheight: 400px;
  position: absolute;
  top: 0;
  left: 0;
  bottom: auto;
  right: 0;
  width: 100%;
  padding-bottom: 170px;
  color: white;
`;

const Header = styled('h1')`
  font-size: 4em !important;
  color: white;
  font-weight: normal;
  margin-bottom: 0;
`;

const SubHeader = styled('h2')`
  margin: 0;
  color: white;
`;



const Video = styled('img')`
  margin: 0;
  width: 100%;
  @media only screen and (max-width: 800px) {
    display: none;
  }
`;
const RootContainer = styled('div')`
  max-height: 800px;
  @media only screen and (max-width: 800px) {
    background: #123962;
    min-height: 700px;
  }
  height: 75%;
  position: relative;
  overflow: hidden;
`;

const WelcomeScreen = ({ currentMetaData }: Props) => (
  <RootContainer>
    <Video
      autoPlay
      autostart
      className="cover-video"
      loop
      muted
      src={glos}
    />
    <MainContainer text>
      <Flex column spaceBetween>
        <FlexItem>
          <Header>
            <b>SKK</b> {currentMetaData.year}
          </Header>
          <SubHeader>13-15. mars 2019</SubHeader>
          <h3 style={{ color: 'white' }}>NTNU</h3>
        </FlexItem>
        <FlexItem>
          <Countdown currentMetaData={currentMetaData} />
        </FlexItem>
      </Flex>
    </MainContainer>
  </RootContainer>
);

export default WelcomeScreen;
