//@flow
import React from 'react';

import CollaboratorView from './Collaborator';
import Flex from 'styled-flex-component';
import styled from 'styled-components';

type Props = {
  query: Collaborators_query,
  showDescription?: boolean,
  showJoblistings?: boolean
};

const Title = styled('h1')`
  text-align: center;
`;

const Collaborators = ({ showDescription, collabarators }: Props) => {
  return (
    <>
      <Title>Våre samarbeidspartnere</Title>
      <Flex wrap justifyCenter>
        {collabarators.map(company => (
          <CollaboratorView
            showJoblistings={false}
            showDescription={showDescription}
            key={company.id}
            company={company}
          />
        ))}
      </Flex>
    </>
  );
};
export default Collaborators;
