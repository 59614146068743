import React from 'react';
import Flex, { FlexItem } from 'styled-flex-component';
import { Image, CenterIt } from '../Styled';
import styled from 'styled-components';
import deloitte from '../../static/deloitte.png';
const HSPLogo = styled(Image)`
  width: 350px;
  max-width: 100%;
  padding-bottom: 14px;
`;

const HSP = () => (
  <Flex justifyAround wrapReverse>
    <FlexItem grow={1} basis="500px">
      <CenterIt>
        <h1>Hovedsamarbeidspartner</h1>
        <p>
          <i>
            Vi er stolte av å kunne presentere Deloitte som
            hovedsamarbeidspartner i 2019.
          </i>
        </p>
        <h2>Hvem er Deloitte</h2>
        <p>
          <i>
            Deloitte er en arbeidsplass for fremtiden. Vi er en virksomhet med
            høyt utdannede mennesker innen mange forskjellige fagfelt, som
            arbeider sammen for å skape de beste løsningene for våre kunder.
            Våre medarbeidere er en del av en organisasjon som bidrar til et
            bærekraftig næringsliv og samfunn; vi utgjør en forskjell. Vi er
            verdens største leverandør av profesjonelle tjenester innen
            revisjon, rådgivning og advokattjenester med ca. 1400 medarbeidere i
            Norge. Internasjonalt er vi mer enn 286 000 medarbeidere i over 150
            land. I Deloitte vil du få mulighet til å jobbe på prosjekt innenfor
            mange ulike bransjer, tilpasset dine kvalifikasjoner. En karriere i
            Deloitte vil åpne en verden av muligheter for deg.
          </i>
        </p>
      </CenterIt>
    </FlexItem>
    <FlexItem>
      <a href="http://www.deloitte.no/">
        <HSPLogo src={deloitte} alt="Deloitte logo" />
      </a>
    </FlexItem>
  </Flex>
);
export default HSP;
